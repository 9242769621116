<!--招商动态列表-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/xiangningqing'}">乡宁旺</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/business'}">招商引资</el-breadcrumb-item>
                <el-breadcrumb-item >招商动态</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="p-list w-1400">
            <div class="p-1f">
                <el-row :gutter="30">
                    <el-col :span="11">
                        <div class="p-1f-left">
                            <el-image style="width:100%;height:100%" :src="require('../../../../public/img/xnw/b-list1.png')"></el-image>
                            <div class="p-1f-left-title ellipsis">西宁市城西区政企联合招商中心暨绿地分中心揭牌成立</div>
                        </div>
                    </el-col>
                    <el-col :span="13">
                        <div class="p-1f-right">
                            <div class="p-h4 row"><i>重点推荐</i> </div>
                            <div class="p-1f-right-item ellipsis" v-for="item in 10" :key="item + '11'">
                                【乡村振兴在青海】白杨村的“双色发展”之路，经过初次筛选全会通过次筛选全会通过
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="p-2f">
                <el-row :gutter="30">
                    <el-col :span="11">
                        <div class="p-2f-left">
                            <div class="p-h4 row"><i>今日要闻</i> </div>
                            <div class="p-2f-left-box">
                                <el-row :gutter="20">
                                    <el-col :span="12" v-for="item in 2" :key="item + '2f-pic'">
                                        <div class="p-2f-left-item">
                                            <el-image style="width:100%;height:100%" :src="require('../../../../public/img/xnw/b-list2.png')"></el-image>
                                            <div class="p-1f-left-title ellipsis">招商推介会现场</div>
                                        </div>
                                        
                                    </el-col>
                                </el-row>
                            </div>
                            
                        </div>
                    </el-col>
                    <el-col :span="13">
                        <div class="p-1f-right">
                            <div class="p-h4 row"><i>重点推荐</i> </div>
                            <div class="p-1f-right-item ellipsis" v-for="item in 10" :key="item + '11'">
                                【乡村振兴在青海】白杨村的“双色发展”之路，经过初次筛选全会通过次筛选全会通过
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div style="height:150px"></div>
    </div>
</template>
<script>
import {regHtml} from '@/utils/reg'
import {GetZSDTinfos} from '@/api/xiangningwang'
export default {
    components:{
    },
    data(){
        return{
            ZSDTparams:{
                currentPage: 1,
                filter: {title: "", infoType: 257023242199109,secondInfoType: "重点推荐"},
                pageSize: 10,
            },
        }
    }
}
</script>
<style scoped>
.p-2f-left-item{
    height: 360px;
    position: relative;
}
.p-2f-left-box{
    height: 360px;
}
.p-1f-right-item{
    font-size: 18px;
    line-height: 36px;
}
.p-h4>i{
    border-top: 3px solid #dc5238;
    display: block;
}
.p-2f{
    margin-top: 30px;
}
.p-h4{
    line-height: 40px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 17px;
}
.p-2f-left{
    height: 420px;
    overflow: hidden;
    border-top: 1px solid #777;
}
.p-1f-right{
    height: 420px;
    overflow: hidden;
    border-top: 1px solid #777;
    padding: 0 20px;
}
.p-1f-left-title{
    line-height: 48px;
    background: rgba(0, 0, 0, .6);
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
    text-align: center;
    font-size: 20px;
}
.p-1f-left{
    position: relative;
    height: 420px;
}
.picbox{
    position: absolute;
    top: -220px;
    left: 0;
    width: 240px;
    height: 220px;
    background: url(../../../../public/img/xnw/menu1.png) no-repeat center center;
    border: 3px solid #dc5238;
}

.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>